import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Box,
  Divider,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation } from "react-router-dom";
import { FeaturesContext } from "../../context/FeatureContext";
import * as Routes from "../../constants/RouteConstants";
import ICONS from "../../constants/SideBarIconConstant";

// Drawer width used for pushing the content
const drawerWidth = 240;

// Use project ID from env vars
const ClientProjectID = process.env.REACT_APP_PROJECT_ID;
const NavItem = ({ item, isActive, onClick }) => {
  const theme = useTheme();
  return (
    <ListItem disablePadding>
      <ListItemButton
        component={Link}
        to={item.href}
        selected={isActive}
        onClick={onClick}
        sx={{
          transition: theme.transitions.create("background-color", {
            duration: theme.transitions.duration.standard,
          }),
          "&.Mui-selected": {
            backgroundColor: "#3f51b5",
            color: "white",
            borderLeft: "4px solid #283593",
            borderRadius: "0 30px 30px 0",
            marginLeft: "4px",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              backgroundColor: "#3f51b5",
            },
          },
          "&:not(.Mui-selected):hover": {
            backgroundColor: "#f5f5f5",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 40,
            color: isActive ? "white" : "inherit",
          }}
        >
          {item.isCustomIcon ? (
            <img
              src={item.icon}
              alt={item.label}
              width={24}
              height={24}
              loading="lazy"
              style={
                isActive
                  ? { filter: "brightness(0) invert(1)" }
                  : {
                      filter:
                        "invert(23%) sepia(75%) saturate(1261%) hue-rotate(222deg) brightness(91%) contrast(88%)",
                    }
              }
            />
          ) : (
            <item.icon fontSize="medium" />
          )}
        </ListItemIcon>
        <ListItemText primary={item.label} />
      </ListItemButton>
    </ListItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { features } = useContext(FeaturesContext);
  const [isOpen, setIsOpen] = useState(false);

  // Define header and footer heights (adjust as needed)
  const headerHeight = theme.spacing(8.5);
  const footerHeight = theme.spacing(8.6);

  // Refs for the drawer content and the toggle button
  const drawerRef = useRef(null);
  const toggleButtonRef = useRef(null);

  // Automatically close the drawer on mobile when route changes
  useEffect(() => {
    if (isMobile) {
      setIsOpen(false);
    }
  }, [location.pathname, isMobile]);

  // Update CSS variable for main content push (if used elsewhere)
  useEffect(() => {
    document.documentElement.style.setProperty(
      "--drawer-width",
      isOpen ? `${drawerWidth}px` : "0px"
    );
  }, [isOpen]);

  // Close the drawer when clicking outside of it (and outside the toggle button)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        drawerRef.current &&
        !drawerRef.current.contains(event.target) &&
        (!toggleButtonRef.current ||
          !toggleButtonRef.current.contains(event.target))
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  // Close the drawer when scrolling anywhere on the page
  useEffect(() => {
    const handleScroll = () => {
      if (isOpen) {
        setIsOpen(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isOpen]);

  // Build navigation items dynamically
  const navItems = [];

  // Home (always)
  navItems.push({
    label: "Home",
    href: Routes.HOME_ROUTE,
    icon: ICONS.home,
    isCustomIcon: true,
  });

  // Search Patients
  if (features.search_patients) {
    navItems.push({
      label: "Search",
      href: Routes.SEARCH_PATIENTS,
      icon: ICONS.search,
      isCustomIcon: true,
    });
  }

  // Analytics
  if (features.analytics) {
    navItems.push({
      label: "Analytics",
      href: Routes.ANALYTICS_ROUTE,
      icon: ICONS.analytics,
      isCustomIcon: true,
    });
  }

  // Risk Analysis (Emergency/PDCM)
  if (features.risk_analysis) {
    navItems.push({
      label:
        ClientProjectID === "max" ? "Emergency" : "Post Discharge Care     ",
      href: Routes.RISK_ANALYSIS_ROUTE,
      icon: ICONS.discharge,
      isCustomIcon: true,
    });
  }

  // OP Comprehensive & IP Potential
  if (features.opd_analysis) {
    navItems.push({
      label: "OPD Care",
      href: Routes.OP_COMPREHENSIVE_ROUTE,
      icon: ICONS.doctor,
      isCustomIcon: true,
    });
    navItems.push({
      label: "High IPD Risk",
      href: Routes.OP_HIGH_IP_POTENTIAL_ROUTE,
      icon: ICONS.ipPotential,
      isCustomIcon: true,
    });
  }

  // Recommendations (Recommend and Non IP Recommend)
  if (features.recommendations) {
    navItems.push({
      label: "Admission Needed",
      href: Routes.RECOMMENDATION_LIST,
      icon: ICONS.ipd,
      isCustomIcon: true,
    });
  }

  // Camp Analysis & Camp Patients (both labeled "Camp")
  if (features.camp_analysis) {
    navItems.push({
      label: "Outreach Campsamp",
      href: Routes.CAMP_ANALYSIS_ROUTE,
      icon: ICONS.camp,
      isCustomIcon: true,
    });
  }
//   if (features.camp_patients) {
//     navItems.push({
//       label: "Camps",
//       href: Routes.ADMIN_CAMP_PATIENTS_LIST_ROUTE,
//       icon: ICONS.camp,
//       isCustomIcon: true,
//     });
//   }

  // Winback Analysis
  if (features.winback_analysis) {
    navItems.push({
      label: "Re-Engagement",
      href: Routes.WINBACK_ANALYSIS_ROUTE,
      icon: ICONS.winback,
      isCustomIcon: true,
    });
  }

  // Sales Dashboard
  if (features.sales_dashboard) {
    navItems.push({
      label: "Sales",
      href: Routes.SALES_DASHBOARD_ROUTE,
      icon: ICONS.analytics,
      isCustomIcon: true,
    });
  }

  // File Upload
  if (features.file_upload) {
    navItems.push({
      label: "Upload",
      href: Routes.FILE_UPLOAD_ROUTE,
      icon: ICONS.ipd,
      isCustomIcon: true,
    });
  }

  // List Sales User
  if (features.list_sales_user) {
    navItems.push({
      label: "Users",
      href: Routes.LIST_SALES_USER_ROUTE,
      icon: ICONS.ipd,
      isCustomIcon: true,
    });
  }

  // Sales Agent Analytics
  if (features.sales_agent_analytics) {
    navItems.push({
      label: "Agent",
      href: Routes.SALES_AGENT_ANALYTICS_ROUTE,
      icon: ICONS.analytics,
      isCustomIcon: true,
    });
  }

  // Function to open the drawer (only if it’s not already open)
  const openDrawer = () => {
    if (!isOpen) {
      setIsOpen(true);
    }
  };

  return (
    <>
      {/* Toggle Button: Only render if the sidebar is closed */}
      {!isOpen && (
        <IconButton
          ref={toggleButtonRef}
          onClick={openDrawer}
          sx={{
            position: "fixed",
            top: `calc(${headerHeight} + ${theme.spacing(2)})`,
            left: theme.spacing(2),
            zIndex: theme.zIndex.drawer + 1,
            bgcolor: "#3f51b5",
            color: "white",
            transition: theme.transitions.create(
              ["transform", "background-color", "left"],
              {
                duration: theme.transitions.duration.standard,
              }
            ),
            transform: "rotate(0deg) translateY(-70px)",
            "&:hover": { bgcolor: "#303f9f" },
          }}
          aria-label="Open sidebar"
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Sidebar Drawer starting below the header and stopping above the footer */}
      <Drawer
        variant={isMobile ? "temporary" : "persistent"}
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        transitionDuration={500}
        ModalProps={{
          keepMounted: true, // Better performance on mobile.
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            top: headerHeight,
            bottom: footerHeight,
            height: `calc(100% - ${headerHeight} - ${footerHeight})`,
            width: drawerWidth,
            boxSizing: "border-box",
            transition: theme.transitions.create("transform", {
              duration: theme.transitions.duration.standard,
            }),
            background: "linear-gradient(135deg, #fff9c4 0%, #fffde7 100%)",
          },
        }}
      >
        <Box
          ref={drawerRef}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
          }}
        >
          <Divider />
          {/* Navigation List */}
          <List>
            {navItems.map((item, index) => (
              <NavItem
                key={index}
                item={item}
                isActive={location.pathname === item.href}
                onClick={() => {
                  if (isMobile) setIsOpen(false);
                }}
              />
            ))}
          </List>
          {/* You can include a footer inside the sidebar if needed */}
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
   